import React from 'react';
import Header from '../../components/Header/';
import Footer from '../../components/Footer';
import Container from '../../components/Container/index';

const About = () => {
    return (

        <>
            <Header />
            <Container >
                <div>
                    <h1>About Us</h1>
                    <p>This is the About page...</p>
                </div>
                <div>
                    <h1>About Us</h1>
                    <p>This is the About page...</p>
                </div>
                <div>
                    <h1>About Us</h1>
                    <p>This is the About page...</p>
                </div>
                <div>
                    <h1>About Us</h1>
                    <p>This is the About page...</p>
                </div>
                <div>
                    <h1>About Us</h1>
                    <p>This is the About page...</p>
                </div>
                <div>
                    <h1>About Us</h1>
                    <p>This is the About page...</p>
                </div>
                <div>
                    <h1>About Us</h1>
                    <p>This is the About page...</p>
                </div>
                <div>
                    <h1>About Us</h1>
                    <p>This is the About page...</p>
                </div>
                <div>
                    <h1>About Us</h1>
                    <p>This is the About page...</p>
                </div>
                <div>
                    <h1>About Us</h1>
                    <p>This is the About page...</p>
                </div>
                <div>
                    <h1>About Us</h1>
                    <p>This is the About page...</p>
                </div>
                <div>
                    <h1>About Us</h1>
                    <p>This is the About page...</p>
                </div>
                <div>
                    <h1>About Us</h1>
                    <p>This is the About page...</p>
                </div>
                <div>
                    <h1>About Us</h1>
                    <p>This is the About page...</p>
                </div>

            </Container>

            <Footer />
        </>
    );
};

export default About;
